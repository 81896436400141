body {
  margin: 0;
  padding: 0;
}

* {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button,
a {
  cursor: pointer;
}

button {
  border: none;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

.ql-editor {
  padding: 0px;
}

.ql-editor img {
  max-width: 712px;
}
